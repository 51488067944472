import loginStyle from "./Login.module.css";
import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Layout from "../../components/layouts/Layout";
import Tagline from "./components/Tagline";
import LoginComponent from "./components/LoginComponent";
import { login } from "../../Api/api-libs";
import { setAuthToken } from "../../utils/auth";

export default function Login() {
  const [credentials, setCredentials] = useState({
    users_email: "",
    users_password: "",
  });
  const [validation, setValidation] = useState([]);
  const [msgResponse, setMsgResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const { setIsAuth } = useContext(AuthContext);

  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const url_referrer = searchParams.get("url_referrer") || "";

  const handleInputChange = (identifier, value) => {
    setCredentials((prev) => ({
      ...prev,
      [identifier]: value,
    }));
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const fetchLogin = await login(credentials);
      const user = fetchLogin?.data;
      setAuthToken(user);
      setIsAuth(true);
      if (url_referrer) {
        let url_to_decode = window.atob(url_referrer);
        window.location.reload(navigate(url_to_decode));
      } else {
        window.location.reload(navigate("/lowongan-kerja"));
      }
    } catch (error) {
      if (error.response.status === 401) {
        setMsgResponse(error?.response?.data?.message);
        setValidation([]);
      }
      setValidation(error.response.data);
    }

    setLoading(false);
  };

  return (
    <div>
      <Layout title="Sign In">
        <div className={loginStyle.body}>
          <div className={loginStyle.mainLoginWrapper}>
            <LoginComponent
              msgResponse={msgResponse}
              onSubmit={loginHandler}
              credentials={credentials}
              onChange={handleInputChange}
              validation={validation}
              loading={loading}
              urlReferrer={url_referrer}
            />
            <Tagline />
          </div>
        </div>
      </Layout>
    </div>
  );
}
