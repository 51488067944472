import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import WindowedSelect, { createFilter } from "react-windowed-select";
import { Markup } from "interweave";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import style from "./Profile.module.css";
import api from "../../Api/api";
import axios from "axios";
import { Month } from "./Data/month.ts";
import { generateYear } from "./Data/helper";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import { ScrollTop } from "../../utils/ScrollTop";

export default function PengalamanKerja() {
  const [showForm, setShowForm] = useState(false);
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [jobsCategory, setJobsCategory] = useState([]);
  const List_year = generateYear();
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFail, setAlertFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const customFilter = createFilter({ ignoreAccents: false });

  const [action, setAction] = useState(1);
  const [dataPengalamanKerjaForShow, setDataPengalamanKerjaForShow] = useState(
    []
  );
  const [dataPengalamanForUpdate, setDataPengalamanForUpdate] = useState({
    work_experience_id: "",
    work_experience_job_title: "",
    jobs_category_id: "",
    work_experience_company: "",
    country_id: "",
    city_id: "",
    work_experience_currently_work: false,
    work_experience_from_month: "",
    work_experience_from_year: "",
    work_experience_to_month: "",
    work_experience_to_year: "",
    work_experience_description: "",
  });

  // work description
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  useEffect(() => {
    fetchCountryCity();
    fetchJobsCategory();
    showPengalamanKerja();
  }, []);

  const fetchCountryCity = async () => {
    const users = JSON.parse(localStorage.getItem("user"));

    await axios
      .get(api.countryCity, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setCountry(response.data.country);
        setCity(response.data.city);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchJobsCategory = async () => {
    const users = JSON.parse(localStorage.getItem("user"));

    await axios
      .get(api.jobsCategory, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setJobsCategory(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showPengalamanKerja = async () => {
    setLoading(true);
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.workExperience}?users_email=${users.user_email}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setDataPengalamanKerjaForShow(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const fillValueFormUpdate = (id) => {
    setAction(2);
    dataPengalamanKerjaForShow
      .filter((data) => data.work_experience_id === id)
      .map((data) => {
        setDataPengalamanForUpdate((prev) => ({
          ...prev,
          work_experience_id: data.work_experience_id,
          work_experience_job_title: data.work_experience_job_title,
          jobs_category_id: data.jobs_category_id,
          work_experience_company: data.work_experience_company,
          country_id: data.country_id,
          city_id: data.city_id,
          work_experience_currently_work:
            data.work_experience_currently_work === "true" ? true : false,
          work_experience_from_month: data.work_experience_from_month,
          work_experience_from_year: data.work_experience_from_year,
          work_experience_to_month: data.work_experience_to_month,
          work_experience_to_year: data.work_experience_to_year,
        }));

        setConvertedContent(data.work_experience_description);
        const html = data.work_experience_description;
        const contentBlocks = convertFromHTML(html);
        const contentState = ContentState.createFromBlockArray(
          contentBlocks.contentBlocks,
          contentBlocks.entityMap
        );
        setEditorState(() => EditorState.createWithContent(contentState));
      });
  };

  const insertPengalamanKerja = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.workExperience}?users_email=${users.user_email}`;

    const data = {
      work_experience_job_title:
        dataPengalamanForUpdate.work_experience_job_title,
      jobs_category_id: dataPengalamanForUpdate.jobs_category_id,
      work_experience_company: dataPengalamanForUpdate.work_experience_company,
      country_id: dataPengalamanForUpdate.country_id,
      city_id: dataPengalamanForUpdate.city_id,
      work_experience_currently_work: `${dataPengalamanForUpdate.work_experience_currently_work}`,
      work_experience_from_month:
        dataPengalamanForUpdate.work_experience_from_month,
      work_experience_from_year:
        dataPengalamanForUpdate.work_experience_from_year,
      work_experience_to_month:
        dataPengalamanForUpdate.work_experience_to_month,
      work_experience_to_year: dataPengalamanForUpdate.work_experience_to_year,
      work_experience_description: convertedContent,
    };

    await axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setAlertSuccess(true);
        setTimeout(() => {
          setAlertSuccess(false);
        }, 3000);
        ScrollTop();
        showPengalamanKerja();
        setDataPengalamanForUpdate((prev) => ({
          ...prev,
          jobs_category_id: null,
        }));
        setDataPengalamanForUpdate((prev) => ({
          ...prev,
          work_experience_id: "",
          work_experience_job_title: "",
          jobs_category_id: null,
          work_experience_company: "",
          country_id: null,
          city_id: null,
          work_experience_currently_work: false,
          work_experience_from_month: "",
          work_experience_from_year: "",
          work_experience_to_month: "",
          work_experience_to_year: "",
        }));
        setConvertedContent("");
        setEditorState(() =>
          EditorState.createWithContent(ContentState.createFromText(""))
        );
        setAction(1);
      })
      .catch((error) => {
        console.log(error);
        setAlertFail(true);
        setTimeout(() => {
          setAlertFail(false);
        }, 3000);
        ScrollTop();
        setDataPengalamanForUpdate((prev) => ({
          ...prev,
          jobs_category_id: null,
        }));
        setDataPengalamanForUpdate((prev) => ({
          ...prev,
          work_experience_id: "",
          work_experience_job_title: "",
          jobs_category_id: null,
          work_experience_company: "",
          country_id: null,
          city_id: null,
          work_experience_currently_work: false,
          work_experience_from_month: "",
          work_experience_from_year: "",
          work_experience_to_month: "",
          work_experience_to_year: "",
        }));
        setConvertedContent("");
        setEditorState(() =>
          EditorState.createWithContent(ContentState.createFromText(""))
        );
        setAction(1);
      });
  };

  const updatePengalamanKerja = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.workExperience}/${dataPengalamanForUpdate.work_experience_id}`;

    const data = {
      work_experience_job_title:
        dataPengalamanForUpdate.work_experience_job_title,
      jobs_category_id: dataPengalamanForUpdate.jobs_category_id,
      work_experience_company: dataPengalamanForUpdate.work_experience_company,
      country_id: dataPengalamanForUpdate.country_id,
      city_id: dataPengalamanForUpdate.city_id,
      work_experience_currently_work: `${dataPengalamanForUpdate.work_experience_currently_work}`,
      work_experience_from_month:
        dataPengalamanForUpdate.work_experience_from_month,
      work_experience_from_year:
        dataPengalamanForUpdate.work_experience_from_year,
      work_experience_to_month:
        dataPengalamanForUpdate.work_experience_to_month,
      work_experience_to_year: dataPengalamanForUpdate.work_experience_to_year,
      work_experience_description: convertedContent,
    };

    await axios
      .put(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setAlertSuccess(true);
        setTimeout(() => {
          setAlertSuccess(false);
        }, 3000);
        ScrollTop();
        showPengalamanKerja();
        setDataPengalamanForUpdate((prev) => ({
          ...prev,
          jobs_category_id: null,
        }));
        setDataPengalamanForUpdate((prev) => ({
          ...prev,
          work_experience_id: "",
          work_experience_job_title: "",
          jobs_category_id: null,
          work_experience_company: "",
          country_id: null,
          city_id: null,
          work_experience_currently_work: false,
          work_experience_from_month: "",
          work_experience_from_year: "",
          work_experience_to_month: "",
          work_experience_to_year: "",
        }));
        setConvertedContent("");
        setEditorState(() =>
          EditorState.createWithContent(ContentState.createFromText(""))
        );
        setAction(1);
      })
      .catch((error) => {
        console.log(error);
        setAlertFail(true);
        setTimeout(() => {
          setAlertFail(false);
        }, 3000);
        ScrollTop();
        setDataPengalamanForUpdate((prev) => ({
          ...prev,
          jobs_category_id: null,
        }));
        setDataPengalamanForUpdate((prev) => ({
          ...prev,
          work_experience_id: "",
          work_experience_job_title: "",
          jobs_category_id: null,
          work_experience_company: "",
          country_id: null,
          city_id: null,
          work_experience_currently_work: false,
          work_experience_from_month: "",
          work_experience_from_year: "",
          work_experience_to_month: "",
          work_experience_to_year: "",
        }));
        setConvertedContent("");
        setEditorState(() =>
          EditorState.createWithContent(ContentState.createFromText(""))
        );
        setAction(1);
      });
  };

  const deletePengalamanKerja = async (id) => {
    Swal.fire({
      title: "Delete work experiennce?",
      text: "Are you sure want to delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F39058",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const users = JSON.parse(localStorage.getItem("user"));
        const url = `${api.workExperience}/${id}`;

        axios
          .delete(url, {
            headers: {
              "Content-type": "application/json",
              Authorization: `bearer ${users?.token}`,
            },
          })
          .then((response) => {
            Swal.fire({
              title: "Success",
              text: "Success delete data",
              icon: "success",
              confirmButtonColor: "#F39058",
            });
            setDataPengalamanForUpdate((prev) => ({
              ...prev,
              jobs_category_id: null,
            }));
            setDataPengalamanForUpdate((prev) => ({
              ...prev,
              work_experience_id: "",
              work_experience_job_title: "",
              jobs_category_id: null,
              work_experience_company: "",
              country_id: null,
              city_id: null,
              work_experience_currently_work: false,
              work_experience_from_month: "",
              work_experience_from_year: "",
              work_experience_to_month: "",
              work_experience_to_year: "",
            }));
            setConvertedContent("");
            setEditorState(() =>
              EditorState.createWithContent(ContentState.createFromText(""))
            );
            showPengalamanKerja();
            setAction(1);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Failed",
              text: "Failed delete data",
              icon: "error",
              confirmButtonColor: "#F39058",
            });
            setDataPengalamanForUpdate((prev) => ({
              ...prev,
              jobs_category_id: null,
            }));
            setDataPengalamanForUpdate((prev) => ({
              ...prev,
              work_experience_id: "",
              work_experience_job_title: "",
              jobs_category_id: null,
              work_experience_company: "",
              country_id: null,
              city_id: null,
              work_experience_currently_work: false,
              work_experience_from_month: "",
              work_experience_from_year: "",
              work_experience_to_month: "",
              work_experience_to_year: "",
            }));
            setConvertedContent("");
            setEditorState(() =>
              EditorState.createWithContent(ContentState.createFromText(""))
            );
            showPengalamanKerja();
            setAction(1);
          });
      }
    });
  };

  const handleClearDataPengalamanForUpdate = () => {
    setDataPengalamanForUpdate((prev) => ({
      ...prev,
      jobs_category_id: null,
    }));
    setDataPengalamanForUpdate((prev) => ({
      ...prev,
      work_experience_id: "",
      work_experience_job_title: "",
      jobs_category_id: null,
      work_experience_company: "",
      country_id: null,
      city_id: null,
      work_experience_currently_work: false,
      work_experience_from_month: "",
      work_experience_from_year: "",
      work_experience_to_month: "",
      work_experience_to_year: "",
    }));
    setConvertedContent("");
    setEditorState(() =>
      EditorState.createWithContent(ContentState.createFromText(""))
    );
    setAction(1);
  };

  // options value
  const jobsCategoryOptions = jobsCategory.map((item) => {
    return { value: item.jobs_category_id, label: item.jobs_category_name };
  });

  const countryOptions = country.map((item) => {
    return { value: item.country_id, label: item.country_name };
  });

  const cityOptions = city
    .filter(
      (c) => c.country_id === parseInt(dataPengalamanForUpdate?.country_id)
    )
    .map((item) => {
      return { value: item.city_id, label: item.city_name };
    });

  return (
    <div className="w-full p-[20px] xl:p-[30px] min-h-screen">
      {loading && (
        <>
          <div className="w-full pt-20">
            <div className="flex items-center justify-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}

      {!loading && (
        <>
          <div className="flex flex-col items-start gap-[40px] md:justify-between md:flex-row">
            <div className="border-b-4 border-[#F39058] text-2xl font-semibold">
              Work Experience
            </div>
            {!showForm && (
              <button
                onClick={() => {
                  setShowForm(!showForm);
                }}
                className="py-2 px-10 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] text-sm"
              >
                Change
              </button>
            )}
          </div>

          {!showForm && (
            <div className="mt-[60px]">
              {dataPengalamanKerjaForShow?.length > 0 ? (
                <>
                  {dataPengalamanKerjaForShow?.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="flex flex-col gap-[30px] xl:flex-row mb-[50px]">
                          <div className="w-full xl:w-[40%]">
                            {data?.work_experience_currently_work ===
                              "true" && (
                              <div className="mb-2 font-semibold">
                                {data?.work_experience_job_title} |{" "}
                                {data?.work_experience_from_year} - Now
                              </div>
                            )}
                            {data?.work_experience_currently_work ===
                              "false" && (
                              <div className="mb-2 font-semibold">
                                {data?.work_experience_job_title} |{" "}
                                {data?.work_experience_from_year} -{" "}
                                {data?.work_experience_to_year}
                              </div>
                            )}
                            <div>
                              {data?.work_experience_company} |{" "}
                              {data?.city_name}, {data?.country_name}
                            </div>
                          </div>
                          <div className="w-full xl:w-[60%]">
                            <div className="flex flex-col gap-[5px] xl:flex-row xl:gap-[68px] mb-2">
                              <div className="font-semibold">Position</div>
                              <div>{data?.work_experience_job_title}</div>
                            </div>
                            <div className="flex flex-col gap-[5px] xl:flex-row xl:gap-[60px] mb-2">
                              <div className="font-semibold">Category</div>
                              <div>{data?.jobs_category_name}</div>
                            </div>
                            <div className="flex flex-col gap-[5px] xl:flex-row xl:gap-[40px] mb-2">
                              <div className="font-semibold">Description</div>
                              <div>
                                <Markup
                                  content={data?.work_experience_description}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="w-full px-10 pt-[70px]">
                  <div className="flex items-center justify-center gap-4 font-semibold">
                    <img src="/images/noData.png" alt="data-not-found" /> No
                    data
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {showForm && (
        <>
          <div className="mt-[60px] w-full">
            <div className="w-full">
              {alertSuccess && (
                <div className="alert alert-success" role="alert">
                  Success save data
                </div>
              )}
              {alertFail && (
                <div className="alert alert-danger" role="alert">
                  Failed save data
                </div>
              )}
            </div>
            <button
              onClick={() => {
                setShowForm(!showForm);
              }}
              className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mb-4 text-sm"
            >
              <BiArrowBack /> Back
            </button>

            <div className="mt-[20px]">
              {dataPengalamanKerjaForShow?.map((data) => {
                return (
                  <div
                    key={Math.random()}
                    className="w-full bg-white rounded-sm shadow-md py-[15px] px-[30px] flex justify-between items-center mb-3"
                  >
                    <div>
                      <div className="mb-2 font-bold">
                        {data?.work_experience_job_title}
                      </div>
                      <div className="mb-1 text-sm">
                        {data?.work_experience_company} | {data?.city_name},{" "}
                        {data?.country_name}
                      </div>
                      {data?.work_experience_currently_work === "true" && (
                        <div className="text-sm">
                          {data?.work_experience_from_month}{" "}
                          {data?.work_experience_from_year} - Now
                        </div>
                      )}
                      {data?.work_experience_currently_work === "false" && (
                        <div className="text-sm">
                          {data?.work_experience_from_month}{" "}
                          {data?.work_experience_from_year} -{" "}
                          {data?.work_experience_to_month}{" "}
                          {data?.work_experience_to_year}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col md:flex-row gap-3 text-[#FF9A62] text-2xl items-start">
                      <button
                        className="p-2 hover:text-[#9b532d]"
                        onClick={() => {
                          fillValueFormUpdate(data.work_experience_id);
                          const section = document.querySelector("#update");
                          section.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }}
                      >
                        <AiFillEdit />
                      </button>

                      <button
                        onClick={() =>
                          deletePengalamanKerja(data.work_experience_id)
                        }
                        className="p-2 hover:text-[#9b532d]"
                      >
                        <AiFillDelete />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="py-[10px]" id="update"></div>

            <h1 className="py-[15px] font-semibold">
              Add / Update Work Experience
            </h1>

            <div className="w-full">
              {(dataPengalamanForUpdate.work_experience_job_title ||
                dataPengalamanForUpdate.jobs_category_id ||
                dataPengalamanForUpdate.work_experience_company ||
                dataPengalamanForUpdate.country_id ||
                dataPengalamanForUpdate.city_id ||
                dataPengalamanForUpdate.work_experience_from_month ||
                dataPengalamanForUpdate.work_experience_from_year) && (
                <button
                  onClick={handleClearDataPengalamanForUpdate}
                  className="flex items-center gap-2 py-2 text-sm font-semibold text-red-400 hover:text-red-800"
                >
                  Clear all fields <AiFillDelete />
                </button>
              )}
            </div>

            <div>
              <label htmlFor="posisi" className="mt-3 form-label">
                Position <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                name="posisi"
                type="text"
                placeholder="Position"
                id="posisi"
                value={dataPengalamanForUpdate.work_experience_job_title}
                onChange={(e) =>
                  setDataPengalamanForUpdate((prev) => ({
                    ...prev,
                    work_experience_job_title: e.target.value,
                  }))
                }
              />
              {!dataPengalamanForUpdate.work_experience_job_title && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Position required
                </div>
              )}

              <label className="mt-3 form-label">
                Category <span className="text-danger">*</span>
              </label>
              <Select
                isClearable={true}
                options={jobsCategoryOptions}
                onChange={(event) => {
                  event === null
                    ? setDataPengalamanForUpdate((prev) => ({
                        ...prev,
                        jobs_category_id: null,
                      }))
                    : setDataPengalamanForUpdate((prev) => ({
                        ...prev,
                        jobs_category_id: event.value,
                      }));
                }}
                value={
                  dataPengalamanForUpdate.jobs_category_id === null
                    ? null
                    : jobsCategoryOptions.find(
                        (c) =>
                          c.value ===
                          parseInt(dataPengalamanForUpdate?.jobs_category_id)
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Category"
                menuPlacement="auto"
              />
              {!dataPengalamanForUpdate.jobs_category_id && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Category required
                </div>
              )}

              <label htmlFor="perusahaan" className="mt-3 form-label">
                Company Name <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                name="perusahaan"
                type="text"
                placeholder="Company Name"
                id="perusahaan"
                value={dataPengalamanForUpdate?.work_experience_company}
                onChange={(e) =>
                  setDataPengalamanForUpdate((prev) => ({
                    ...prev,
                    work_experience_company: e.target.value,
                  }))
                }
              />
              {!dataPengalamanForUpdate.work_experience_company && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Company Name required
                </div>
              )}

              <label className="mt-3 form-label">
                Country <span className="text-danger">*</span>
              </label>
              <Select
                isClearable={true}
                options={countryOptions}
                onChange={(event) => {
                  event === null
                    ? setDataPengalamanForUpdate((prev) => ({
                        ...prev,
                        country_id: null,
                        city_id: null,
                      }))
                    : setDataPengalamanForUpdate((prev) => ({
                        ...prev,
                        country_id: event.value,
                      }));
                }}
                value={
                  countryOptions.find(
                    (c) =>
                      c.value === parseInt(dataPengalamanForUpdate?.country_id)
                  ) == null
                    ? null
                    : countryOptions.find(
                        (c) =>
                          c.value ===
                          parseInt(dataPengalamanForUpdate?.country_id)
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose country"
                menuPlacement="auto"
              />
              {!dataPengalamanForUpdate.country_id && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Country required
                </div>
              )}

              <label className="mt-3 form-label">
                City <span className="text-danger">*</span>
              </label>
              <WindowedSelect
                filterOption={customFilter}
                isClearable={true}
                options={cityOptions}
                onChange={(event) => {
                  event === null
                    ? setDataPengalamanForUpdate((prev) => ({
                        ...prev,
                        city_id: null,
                      }))
                    : setDataPengalamanForUpdate((prev) => ({
                        ...prev,
                        city_id: event.value,
                      }));
                }}
                value={
                  cityOptions.find(
                    (c) =>
                      c.value === parseInt(dataPengalamanForUpdate?.city_id)
                  ) == null
                    ? null
                    : cityOptions.find(
                        (c) =>
                          c.value === parseInt(dataPengalamanForUpdate?.city_id)
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose city"
                menuPlacement="auto"
              />
              {!dataPengalamanForUpdate.city_id && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  City required
                </div>
              )}

              <label className="mt-3 form-label">Period</label>
              <div className="form-check">
                <input
                  className="cursor-pointer form-check-input"
                  type="checkbox"
                  id="time_periode"
                  value=""
                  checked={
                    dataPengalamanForUpdate.work_experience_currently_work
                  }
                  onChange={() =>
                    setDataPengalamanForUpdate((prev) => ({
                      ...prev,
                      work_experience_currently_work:
                        !dataPengalamanForUpdate.work_experience_currently_work,
                    }))
                  }
                />
                <label
                  className="cursor-pointer form-check-label"
                  htmlFor="time_periode"
                >
                  Still work on here
                </label>
              </div>

              <div className="flex flex-col items-end gap-3 md:flex-row">
                <div className="mt-3 mb-2 w-[100%] md:w-[25%]">
                  <h1 className="mb-2">
                    From <span className="text-danger">*</span>
                  </h1>
                  <Select
                    value={
                      Month.find(
                        (c) =>
                          c.value ===
                          `${dataPengalamanForUpdate.work_experience_from_month}`
                      ) == null
                        ? null
                        : Month.find(
                            (c) =>
                              c.value ===
                              `${dataPengalamanForUpdate.work_experience_from_month}`
                          )
                    }
                    isClearable={true}
                    options={Month}
                    onChange={(event) =>
                      event == null
                        ? setDataPengalamanForUpdate((prev) => ({
                            ...prev,
                            work_experience_from_month: null,
                          }))
                        : setDataPengalamanForUpdate((prev) => ({
                            ...prev,
                            work_experience_from_month: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Month"}
                  />
                </div>

                <div className="mb-2 w-[100%] md:w-[25%]">
                  <Select
                    value={
                      List_year.find(
                        (c) =>
                          c.value ===
                          parseInt(
                            dataPengalamanForUpdate.work_experience_from_year
                          )
                      ) == null
                        ? null
                        : List_year.find(
                            (c) =>
                              c.value ===
                              parseInt(
                                dataPengalamanForUpdate.work_experience_from_year
                              )
                          )
                    }
                    isClearable={true}
                    options={List_year}
                    onChange={(event) =>
                      event == null
                        ? setDataPengalamanForUpdate((prev) => ({
                            ...prev,
                            work_experience_from_year: null,
                          }))
                        : setDataPengalamanForUpdate((prev) => ({
                            ...prev,
                            work_experience_from_year: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Year"}
                  />
                </div>

                <div className="mb-2 w-[100%] md:w-[25%]">
                  <h1 className="mb-2">To</h1>
                  <Select
                    value={
                      Month.find(
                        (c) =>
                          c.value ===
                          `${dataPengalamanForUpdate.work_experience_to_month}`
                      ) == null
                        ? null
                        : Month.find(
                            (c) =>
                              c.value ===
                              `${dataPengalamanForUpdate.work_experience_to_month}`
                          )
                    }
                    isClearable={true}
                    options={Month}
                    onChange={(event) =>
                      event == null
                        ? setDataPengalamanForUpdate((prev) => ({
                            ...prev,
                            work_experience_to_month: null,
                          }))
                        : setDataPengalamanForUpdate((prev) => ({
                            ...prev,
                            work_experience_to_month: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Month"}
                    isDisabled={
                      dataPengalamanForUpdate.work_experience_currently_work ==
                      true
                        ? true
                        : false
                    }
                  />
                </div>

                <div className="mb-2 w-[100%] md:w-[25%]">
                  <Select
                    value={
                      List_year.find(
                        (c) =>
                          c.value ===
                          parseInt(
                            dataPengalamanForUpdate.work_experience_to_year
                          )
                      ) == null
                        ? null
                        : List_year.find(
                            (c) =>
                              c.value ===
                              parseInt(
                                dataPengalamanForUpdate.work_experience_to_year
                              )
                          )
                    }
                    isClearable={true}
                    options={List_year}
                    onChange={(event) =>
                      event == null
                        ? setDataPengalamanForUpdate((prev) => ({
                            ...prev,
                            work_experience_to_year: null,
                          }))
                        : setDataPengalamanForUpdate((prev) => ({
                            ...prev,
                            work_experience_to_year: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Year"}
                    isDisabled={
                      dataPengalamanForUpdate.work_experience_currently_work ==
                      true
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              {(!dataPengalamanForUpdate.work_experience_from_month ||
                !dataPengalamanForUpdate.work_experience_from_year) && (
                <div className="text-[13px] text-red-500">Period required</div>
              )}

              <div className="mt-3">
                <h1 className="mb-2">
                  Job Description <span className="text-danger">*</span>
                </h1>
                <Editor
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName={`wrapper-class ${style.wrapperEditor}`}
                  editorClassName={`editor-class ${style.editorEditor}`}
                  toolbarClassName={`toolbar-class ${style.toolbarEditor} bg-dark`}
                  toolbar={{
                    options: ["inline", "list"],
                    inline: {
                      inDropdown: false,
                      options: ["bold", "italic", "underline"],
                    },
                    list: {
                      inDropdown: false,
                      options: ["unordered", "ordered"],
                    },
                  }}
                  editorState={editorState}
                />
              </div>
              {(convertedContent === null ||
                convertedContent === "<p></p>" ||
                convertedContent === "") && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Job description required
                </div>
              )}

              <div className="flex justify-end w-full">
                {(!dataPengalamanForUpdate.work_experience_job_title ||
                  !dataPengalamanForUpdate.jobs_category_id ||
                  !dataPengalamanForUpdate.work_experience_company ||
                  !dataPengalamanForUpdate.country_id ||
                  !dataPengalamanForUpdate.city_id ||
                  !dataPengalamanForUpdate.work_experience_from_month ||
                  !dataPengalamanForUpdate.work_experience_from_year ||
                  convertedContent === null ||
                  convertedContent === "<p></p>") && (
                  <button
                    className="flex items-center gap-[5px] py-2 px-4 bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed"
                    disabled
                  >
                    Save
                  </button>
                )}

                {dataPengalamanForUpdate.work_experience_job_title &&
                  dataPengalamanForUpdate.jobs_category_id &&
                  dataPengalamanForUpdate.work_experience_company &&
                  dataPengalamanForUpdate.country_id &&
                  dataPengalamanForUpdate.city_id &&
                  dataPengalamanForUpdate.work_experience_from_month &&
                  dataPengalamanForUpdate.work_experience_from_year &&
                  convertedContent !== null &&
                  convertedContent !== "<p></p>" && (
                    <button
                      onClick={
                        action === 1
                          ? insertPengalamanKerja
                          : updatePengalamanKerja
                      }
                      className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mt-4 text-sm"
                    >
                      Simpan
                    </button>
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
