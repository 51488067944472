import { FaUser, FaGraduationCap, FaUserCog } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { MdHomeWork } from "react-icons/md";
import { RiFileUserLine } from "react-icons/ri";

export const Menu = [
  {
    id: 1,
    menu: "My profile",
    icon: <FaUser />,
  },
  {
    id: 2,
    menu: "Personal data",
    icon: <ImProfile />,
  },
  {
    id: 3,
    menu: "Work experience",
    icon: <MdHomeWork />,
  },
  {
    id: 4,
    menu: "Education",
    icon: <FaGraduationCap />,
  },
  {
    id: 5,
    menu: "Skill",
    icon: <FaUserCog />,
  },
  {
    id: 6,
    menu: "Resume",
    icon: <RiFileUserLine />,
  },
];
