import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Select from "react-select";
import { titleCase } from "./Data/helper";
import axios from "axios";
import api from "../../Api/api";
import Swal from "sweetalert2";
import { ScrollTop } from "../../utils/ScrollTop";

export default function Keahlian() {
  const [action, setAction] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [proficiency, setProficiency] = useState([]);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertFail, setAlertFail] = useState(false);
  const [dataKeahlianForShow, setDataKeahlianForShow] = useState([]);
  const [dataKeahlianForUpdate, setDataKeahlianForUpdate] = useState({
    users_skill_id: "",
    skills_id: "",
    skills_name: "",
    proficiency_id: "",
  });

  useEffect(() => {
    fetchProficiency();
    showKeahlian();
  }, []);

  const fetchProficiency = async () => {
    const users = JSON.parse(localStorage.getItem("user"));

    await axios
      .get(api.proficiency, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setProficiency(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showKeahlian = async () => {
    setLoading(true);
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.skill}?users_email=${users.user_email}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setDataKeahlianForShow(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const fillValueFormUpdate = (id) => {
    setAction(2);
    dataKeahlianForShow
      .filter((data) => data.users_skill_id === id)
      .map((data) => {
        setDataKeahlianForUpdate((prev) => ({
          ...prev,
          users_skill_id: data.users_skill_id,
          skills_id: data.skills_id,
          skills_name: data.skills_name,
          proficiency_id: data.proficiency_id,
        }));
      });
  };

  const insertKeahlian = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.skill}?users_email=${users.user_email}`;

    const data = {
      skills_name: dataKeahlianForUpdate.skills_name,
      proficiency_id: dataKeahlianForUpdate.proficiency_id,
    };

    await axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setAlertSuccess(true);
        setTimeout(() => {
          setAlertSuccess(false);
        }, 3000);
        ScrollTop();
        showKeahlian();
        setAction(1);
        setDataKeahlianForUpdate((prev) => ({
          ...prev,
          skills_name: "",
          proficiency_id: null,
        }));
      })
      .catch((error) => {
        console.log(error);
        setAlertFail(true);
        setTimeout(() => {
          setAlertFail(false);
        }, 3000);
        ScrollTop();
        showKeahlian();
        setAction(1);
        setDataKeahlianForUpdate((prev) => ({
          ...prev,
          skills_name: "",
          proficiency_id: null,
        }));
      });
  };

  const updateKeahlian = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.skill}/${dataKeahlianForUpdate.users_skill_id}`;

    const data = {
      skills_name: dataKeahlianForUpdate.skills_name,
      proficiency_id: dataKeahlianForUpdate.proficiency_id,
    };

    await axios
      .put(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setAlertSuccess(true);
        setTimeout(() => {
          setAlertSuccess(false);
        }, 3000);
        ScrollTop();
        showKeahlian();
        setAction(1);
        setDataKeahlianForUpdate((prev) => ({
          ...prev,
          skills_name: "",
          proficiency_id: null,
        }));
      })
      .catch((error) => {
        console.log(error);
        setAlertFail(true);
        setTimeout(() => {
          setAlertFail(false);
        }, 3000);
        ScrollTop();
        showKeahlian();
        setAction(1);
        setDataKeahlianForUpdate((prev) => ({
          ...prev,
          skills_name: "",
          proficiency_id: null,
        }));
      });
  };

  const deleteKeahlian = (id) => {
    Swal.fire({
      title: "Delete skill?",
      text: "Are you sure want to delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F39058",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const users = JSON.parse(localStorage.getItem("user"));
        const url = `${api.skill}/${id}`;

        await axios
          .delete(url, {
            headers: {
              "Content-type": "application/json",
              Authorization: `bearer ${users?.token}`,
            },
          })
          .then((response) => {
            Swal.fire({
              title: "Success",
              text: "Succes save data",
              icon: "success",
              confirmButtonColor: "#F39058",
            });
            setDataKeahlianForUpdate((prev) => ({
              ...prev,
              skills_name: "",
              proficiency_id: null,
            }));
            showKeahlian();
            setAction(1);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Failed",
              text: "Failed save data",
              icon: "error",
              confirmButtonColor: "#F39058",
            });
            setDataKeahlianForUpdate((prev) => ({
              ...prev,
              skills_name: "",
              proficiency_id: null,
            }));
            showKeahlian();
            setAction(1);
          });
      }
    });
  };

  const handleClearDataKeahlianForUpdate = () => {
    setDataKeahlianForUpdate((prev) => ({
      ...prev,
      users_skill_id: "",
      skills_id: "",
      skills_name: "",
      proficiency_id: null,
    }));
    setAction(1);
  };

  // options value
  const proficiencyOptions = proficiency.map((item) => {
    return { value: item.proficiency_id, label: item.proficiency_name };
  });

  return (
    <div className="w-full p-[20px] xl:p-[30px] h-screen">
      {loading && (
        <>
          <div className="w-full pt-20">
            <div className="flex items-center justify-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}

      {!loading && (
        <>
          <div className="flex flex-col items-start gap-[40px] md:justify-between md:flex-row">
            <div className="border-b-4 border-[#F39058] text-2xl font-semibold">
              Skill
            </div>
            {!showForm && (
              <button
                onClick={() => {
                  setShowForm(!showForm);
                }}
                className="py-2 px-10 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] text-sm"
              >
                Change
              </button>
            )}
          </div>

          {!showForm && (
            <div className="mt-[60px]">
              {dataKeahlianForShow?.length > 0 ? (
                <>
                  {dataKeahlianForShow?.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="mb-2">
                          {titleCase(data?.skills_name)} |{" "}
                          <span className="font-semibold">
                            {data.proficiency_name}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="w-full px-10 pt-[70px]">
                  <div className="flex items-center justify-center gap-4 font-semibold">
                    <img src="/images/noData.png" alt="data-not-found" /> No
                    data
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {showForm && (
        <>
          <div className="mt-[60px]">
            <div className="w-full">
              {alertSuccess && (
                <div className="alert alert-success" role="alert">
                  Success save data
                </div>
              )}
              {alertFail && (
                <div className="alert alert-danger" role="alert">
                  Failed save data
                </div>
              )}
              <button
                onClick={() => {
                  setShowForm(!showForm);
                }}
                className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mb-4 text-sm"
              >
                <BiArrowBack /> Back
              </button>

              <div className="mt-[20px]">
                {dataKeahlianForShow?.map((data) => {
                  return (
                    <div
                      key={Math.random()}
                      className="w-full bg-white rounded-sm shadow-md py-[15px] px-[30px] flex justify-between items-center mb-3"
                    >
                      <div>
                        <div className="mb-2 font-bold">
                          {titleCase(data?.skills_name)}
                        </div>
                        <div className="mb-1 text-sm">
                          {data?.proficiency_name}
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row gap-3 text-[#FF9A62] text-2xl items-start">
                        <button
                          className="p-2 hover:text-[#9b532d]"
                          onClick={() => {
                            fillValueFormUpdate(data.users_skill_id);
                            const section = document.querySelector("#update");
                            section.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                          }}
                        >
                          <AiFillEdit />
                        </button>

                        <button
                          onClick={() => deleteKeahlian(data.users_skill_id)}
                          className="p-2 hover:text-[#9b532d]"
                        >
                          <AiFillDelete />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="py-[10px]" id="update"></div>

              <h1 className="py-[15px] font-semibold">Add / Update Skill</h1>

              <div className="w-full">
                {(dataKeahlianForUpdate.skills_name ||
                  dataKeahlianForUpdate.proficiency_id) && (
                  <button
                    onClick={handleClearDataKeahlianForUpdate}
                    className="flex items-center gap-2 py-2 text-sm font-semibold text-red-400 hover:text-red-800"
                  >
                    Clear all fields <AiFillDelete />
                  </button>
                )}
              </div>

              <div>
                <label className="mt-3 form-label">
                  Skill <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="skill"
                  value={dataKeahlianForUpdate?.skills_name}
                  onChange={(e) =>
                    setDataKeahlianForUpdate((prev) => ({
                      ...prev,
                      skills_name: e.target.value.toLowerCase(),
                    }))
                  }
                />
                {!dataKeahlianForUpdate.skills_name && (
                  <div className="mt-[8px] text-[13px] text-red-500">
                    Skill required
                  </div>
                )}

                <label className="mt-3 form-label">
                  Proficiency <span className="text-danger">*</span>
                </label>
                <Select
                  value={
                    proficiencyOptions.find(
                      (c) =>
                        c.value ===
                        parseInt(dataKeahlianForUpdate.proficiency_id)
                    ) == null
                      ? null
                      : proficiencyOptions.find(
                          (c) =>
                            c.value ===
                            parseInt(dataKeahlianForUpdate.proficiency_id)
                        )
                  }
                  isClearable={true}
                  options={proficiencyOptions}
                  onChange={(event) =>
                    event === null
                      ? setDataKeahlianForUpdate((prev) => ({
                          ...prev,
                          proficiency_id: null,
                        }))
                      : setDataKeahlianForUpdate((prev) => ({
                          ...prev,
                          proficiency_id: event.value,
                        }))
                  }
                  components={{ IndicatorSeparator: () => null }}
                  menuPlacement="auto"
                  placeholder="Choose proficiency"
                />
              </div>
              {!dataKeahlianForUpdate.proficiency_id && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Proficiency required
                </div>
              )}

              <div className="flex justify-end w-full">
                {(!dataKeahlianForUpdate.skills_name ||
                  !dataKeahlianForUpdate.proficiency_id) && (
                  <button
                    className="flex items-center gap-[5px] py-2 px-4 bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed"
                    disabled
                  >
                    Save
                  </button>
                )}

                {dataKeahlianForUpdate.skills_name &&
                  dataKeahlianForUpdate.proficiency_id && (
                    <button
                      onClick={action === 1 ? insertKeahlian : updateKeahlian}
                      className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mt-4 text-sm"
                    >
                      Save
                    </button>
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
